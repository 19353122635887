.qr-reader {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    position: relative;
}

.qr-reader video {
    width: 266px;
    height: 266px;
    object-fit: cover;
    position: absolute;
    fill: none;
    left: 13%;
    top: 13%;
    /* transform: translateX(-50%) translateY(-50%); */
}

.qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
}

.qr-reader .qr-frame {
    position: relative;
    fill: none;
    left: 15%;
    top: -22%;
}

/* Media Queries for mobile screens */
/* @media (max-width: 426px) {
    .qr-reader {
        width: 100%;
    }
} */