.chart-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .chart-item {
    flex: 1 1 100%;

  }
  
  @media (min-width: 768px) {
    .chart-container {
      flex-direction: row;
    }
    
    .chart-item {
      flex: 1 1 25%; /* Adjust the percentage based on the number of charts */
    
    }
  }
  