/* Custom toolbar styles */
.custom-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 50px;
  background-color: #86BB57;
}

.custom-toolbar button {
  background-color: #86BB57;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  
}

.custom-toolbar span {
  font-weight: bold;
  font-size: 16px;
}

/* Reduce the font size of the event title */
.rbc-event-content {
  font-size: 8px; /* Reduce the font size */
}


