.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#GFG {
  text-decoration: none !important;
  color: inherit;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url("./assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"), url("./assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "mplus-1c-bold";
  src: local("mplus-1c-bold"), url("./assets/fonts/M-1c/mplus-1c-bold.ttf") format("truetype");
}
@font-face {
  font-family: "mplus-1c-regular";
  src: local("mplus-1c-regular"), url("./assets/fonts/M-1c/mplus-1c-regular.ttf") format("truetype");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.rts___tabs {
  display: flex;
  overflow: auto;
  padding: 0px 0;
}

.truncate {
  padding: 10;
  font-family: mplus-1c-regular;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
#containerImage {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  height: 145px;
  width: 180px;
}

.block {
  width: 140px;
  margin: 0 0 5px 0;
  background-color: red;
}

.tagStyle {
  border-radius: 2px;
  background-color: "red" !important;
}
.carousel-item-padding {
  padding: 0 2px; /* Add 10px horizontal margin between items */
}
.grey-bubble {
  color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
